$(document).ready(function () {
	// Маска <input name="phone">
	$('[name=phone]').mask('+7 (999) 999-99-99');

	// Отправка формы
	$('.ajax-form').on('submit', function(e){
		form = $(this);
		data = form.serialize();
		$('.submit-button').attr('disabled','disabled');
		$.ajax({
			url: './php/main.php?sendLead',
			type: 'POST',
			dataType: 'json',
			data: data,
			success: function() {
				yaCounter47629204.reachGoal('Cel1');
				$('.ajax-form')[0].reset();
				alert('Ваша заявка успешно отправлена');
				$('.submit-button').removeAttr('disabled');
			}
		})
		e.preventDefault();
	});
});